header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  background: linear-gradient(90deg, hsl(4, 77%, 48%) 0%, #bf0d3e 100%);
  color: #fff;
  padding: 20px;
}

.img-position {
  display: flex;
  justify-content: end;
  width: 15%;
}

.img-drapeu {
  width: 35%;
  height: 35%;
}

/* Toggle BTN */

.toggle-switch {
  position: relative;
  width: 115px;
  height: 100%;
  display: inline-block;
  text-align: left;
}

.toggle-input {
  display: none;
}

.toggle-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 18% / 48%;
  position: relative;
  height: 100%;
  width: 100%;
  border: 0.3cap solid #870e2a;
  background-color: #fff;
  transition: background-color 0.3s ease-in-out;
}

.toggle-label:before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background: #782f40;
  border-radius: 50%;
  position: absolute;
  top: 2.5px;
  left: 3px;
  transition: left 0.3s ease-in-out;
}

.toggle-input:checked + .toggle-label {
  background-color: #fff;
}

.toggle-input:checked + .toggle-label:before {
  left: 75%;
}

/* Layout for very large screens (1440px and larger) */
@media (min-width: 1440px) {

  .toggle-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 14% / 48%;
    position: relative;
    height: 100%;
    width: 100%;
    border: 0.3cap solid #870e2a;
    background-color: #fff;
    transition: background-color 0.3s ease-in-out;
  }

  .toggle-label:before {
    width: 27px; 
    height: 27px;
    top: 1px;
    left: 4px;
  }

  .toggle-input:checked + .toggle-label:before {
    left: 72%;
  }
}

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.5%;
  width: 45%;
  margin-left: 8%;
  height: 100%; 
  text-align: center;
}

.feedback-text {
  text-decoration-line: underline;
  text-decoration-color: white;
}

.cta-red-header {
  padding: 12px 20px 12px 20px;
  border-radius: 10px;
  background: #da291c00;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  width: fit-content;
}

.cta-red-header:hover {
  background-color: #bc231800;
}

.toggle-content-left h4,
.toggle-content-left p {
  margin: 0;
  font-size: 85%;
}

.toggle-content-right h4,
.toggle-content-right p {
  margin: 0;
  font-size: 85%;
}

/* Translate BTN */

.translation-menu {
  position: relative;
}

.translation-menu span {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 20px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.dropdown-menu span {
  cursor: pointer;
  color: black;
}

.translation-menu img {
  width: 28px;
  margin-left: 5px;
  border: 2px solid white;
  border-radius: 50%;
}

.container-translation-menu {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
}

#menu-toggle-mobile {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.dropdown-menu-mobile {
  position: absolute;
  background-color: white;
  border: 1px solid #0000001a;
  box-shadow: 0px 10px 20px -30px #0000001a;
  margin-top: 75px;
  cursor: pointer;
  z-index: 1000;
  border-radius: 10px;
  text-align: center;
  padding: 15px 20px 15px 20px;
  width: 27%;
}

.container-toggle-dropdown {
  background-color: rgba(150, 140, 131, 0.1);
  border-radius: 10px;
  padding: 15px 20px 15px 20px;
  width: 30%;
  cursor: pointer;
  margin-bottom: 2.5%;
  display: flex;
  justify-content: center;
}

.toggle-container-dir-head {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
  width: 100%;
  height: 100%;
  text-align: center;
}

.container-toggle-dropdown.active {
  border: 1px solid #00a9e0;
  background-color: #fff;
}

.feedback {
  font-size: medium;
  font-weight: bold;
  font-family: "Nunito";
  color: rgb(10, 9, 9);
  border-radius: 50%;
  background: linear-gradient(90deg, hsla(24, 10%, 90%, 0.833) 0%, #c9a9a6a8 100%);
  height: 50px;
  border-width: 0.5px;
  width: 150px;
}

@media screen and (max-width: 768px) {
  header {
    width: auto;
    margin-top: 0px;
    border-radius: 0;
  }

  .toggle-switch {
    display: none;
  }

  .toggle-content-left {
    display: none;
  }

  .toggle-content-right {
    display: none;
  }
}