#container-cards {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    gap: 10px;
  }
  
  #card {
    text-align: center;
    width: 26.66%;
    height: 301px;
    border: 1px dashed #968c8366;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }
  
  .input-style {
    padding: 15px 20px 15px 20px;
    font-size: 16px;
    border-radius: 10px;
    outline: none;
    transition: border-color 0.3s;
    background-color: #968c831a;
    border: none;
    width: 300px;
  }
  
  .input-style:hover {
    background: #968c8333;
  }
  
  .input-style:focus {
    box-shadow: 0 0 0 2px #00a9e0;
  }
  
  .container-cta {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  
  .sidebar-container {
    display: flex;
    gap: 30px;
    height: 100%;
  }
  
  .section-resultats {
    width: 100%;
    margin-top: 50px;
  }
  
  @media screen and (max-width: 768px) {
    #container-cards {
      display: flex;
      flex-direction: column;
    }
  
    #card {
      width: 90%;
    }
  
    .input-style.input-simple-search {
      width: 80%;
    }
  
    .container-cta {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      z-index: 1000;
      box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 100px;
      padding: 15px;
      margin-bottom: 0;
    }
  
    .sidebar-container {
      gap: 0;
    }
  }
  