.advanced-search {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .advanced-search-with-sidebar {
    height: 100%;
    overflow-y: hidden;
  }
  
  /* .container-input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  } */
  
  .container-input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .tags-container {
    position: relative;
    max-height: 150px;
    overflow-y: auto;
    max-width: 350px;
    margin-top: 10px;
  }
  
  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
  }
  
  .tag {
    padding: 6px 14px;
    border-radius: 20px;
    border: 1px solid #bf0d3e;
    color: #bf0d3e;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .item-input {
    flex: 1 1 calc(25% - 20px);
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  /* Progress bar */
  
  #radius-selector {
    margin: 10px 0;
  }
  
  #radius-selector p {
    margin: 0 0 5px;
  }
  
  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 335px;
    height: 8px;
    background: transparent;
    cursor: pointer;
  }
  
  input[type="range"]:focus {
    outline: none;
  }
  
  input[type="range"]::-webkit-slider-runnable-track {
    width: 335px;
    height: 8px;
    background: #ddd;
    border-radius: 5px;
  }
  
  input[type="range"]::-moz-range-track {
    width: 335px;
    height: 8px;
    background: #ddd;
    border-radius: 5px;
  }
  
  input[type="range"]::-ms-track {
    width: 335px;
    height: 8px;
    background: #ddd;
    border-radius: 5px;
    border: none;
    color: transparent;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 21px;
    height: 21px;
    background: #bf0d3e;
    cursor: pointer;
    border-radius: 50%;
    margin-top: -6.5px;
    border: 2px solid #fff;
  }
  
  input[type="range"]::-moz-range-thumb {
    width: 21px;
    height: 21px;
    background: #bf0d3e;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #fff;
  }
  
  input[type="range"]::-ms-thumb {
    width: 21px;
    height: 21px;
    background: #bf0d3e;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #fff;
  }
  
  input[type="range"]::-webkit-slider-runnable-track {
    background: linear-gradient(
      to right,
      #da291c 0%,
      #bf0d3e var(--value, 0%),
      #ddd var(--value, 0%),
      #ddd 100%
    );
  }
  
  input[type="range"]::-moz-range-progress {
    background-color: #bf0d3e;
    height: 8px;
    border-radius: 5px;
  }
  
  input[type="range"]::-ms-fill-lower {
    background-color: #bf0d3e;
    height: 8px;
    border-radius: 5px;
  }
  
  input[type="range"]::-ms-fill-upper {
    background-color: #ddd;
    height: 8px;
    border-radius: 5px;
  }
  
  /* Type de contrat */
  
  .menu {
    position: absolute;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 999;
    display: flex;
    flex-direction: column;
    width: 300px !important;
    padding: 15px 20px 15px 20px;
    margin-top: 3px;
  }
  
  .validate-button {
    margin-top: 10px;
  }
  
  .menu-open {
    position: relative;
  }
  
  .menu-open .menu {
    top: calc(100% + 5px);
    left: 0;
  }
  
  .checkbox-label {
    background-color: #ffffff;
    padding: 3px;
  }
  
  input[type="checkbox"]:checked + label.checkbox-label {
    background-color: #007bff;
  }
  
  .tag-text {
    margin-right: 5px;
  }
  
  /* Styles pour l'autocomplétion */
  .react-autosuggest__container {
    position: relative;
  }
  
  .react-autosuggest__input {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
  }
  
  .react-autosuggest__suggestions-container {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    width: 335px;
    z-index: 999;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    max-height: 200px;
    overflow-y: auto;
    text-align: left;
  }
  
  .react-autosuggest__suggestions-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .button-position {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }

  .cta-red-realtime {
    padding: 12px 20px 12px 20px;
    border-radius: 10px;
    background: #da291c;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-right: 2.5%;
    font-weight: 600;
    text-decoration: none;
    min-width: 60%;
    max-width: 60%;
  }
  
  
  .cta-red-realtime:hover {
    background-color: #bc2418;
  }

  .cta-red-realtime-right {
    padding: 12px 20px 12px 20px;
    border-radius: 10px;
    background: #da291c;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-left: 2.5%;
    font-weight: 600;
    text-decoration: none;
    min-width: 60%;
    max-width: 60%;
  }
  
  
  .cta-red-realtime-right:hover {
    background-color: #bc2418;
  }
  
  .react-autosuggest__suggestion {
    padding: 10px;
    cursor: pointer;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #f0f0f0;
  }
  
  .input-with-clear {
    position: relative;
    display: flex;
    align-items: center;
    width: 330px;
  }
  
  .input-style {
    width: 100%;
    padding-right: 30px;
    font-family: "Nunito";
  }
  
  .clear-button {
    position: absolute;
    right: 0;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #999;
  }
  
  .clear-button:hover {
    color: #333;
  }
  
  .input-with-icon {
    position: relative;
    width: 340px;
  }
  
  .dropdown-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .checkbox-label.checked {
    color: #00a9e0; 
    background: rgba(0, 169, 224, 0.1);
    padding: 3px;
  }

  .container-cta-real {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1000;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
    padding: 15px;
    margin-bottom: 0;
  }

  .filter {
    text-align: center;
    width: 40% !important;
    margin: 0 auto;
    margin-bottom: 15%;
  }
  
  @media screen and (min-width: 1200px) {
    .item-input {
      margin-right: 30px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .advanced-search {
      width: 100%;
      display: block;
      margin-bottom: 150px;
    }
  
    .container-input {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: nowrap;
      gap: 10px;
      justify-content: center;
    }
  
    input[type="range"] {
      width: 325px;
    }
  
    .item-input {
      padding: 5px;
    }
  
    .input-with-clear {
      width: auto;
    }
  
    .input-with-icon {
      width: auto;
    }
  }


.slot-col {
  padding: 2%;
  position: relative;
  width: auto;
  margin-top: 2%;
  margin-left: 5%;
  margin-right: 5%;
  color: hsla(0, 0%, 20%, 1);
  background-color: rgb(251, 251, 251);
  border: 2px solid transparent;
  background-image: linear-gradient(white, white), 
                    linear-gradient(105deg, rgb(238, 59, 59) 0%, rgb(250, 255, 93) 100%);
  background-origin: border-box; 
  background-clip: padding-box, border-box; 
  border-radius: 12px; 
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}