.div-center {
  text-align: center !important;
  margin: auto !important;
}

.row-main {
  display: flex;
  margin-bottom: 10%;
}

.sovra-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10%;
  width: 100%;
}

.column-table {
  display: flex;
  flex-direction: column;
  min-width: 40%;
  max-width: 40%
}

.inline-div-super {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 8px;
  margin-bottom: 0.3%;
  /* border-color: rgba(151, 5, 5, 0.703);
  border-style: solid; */
}

.inline-div-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  font-weight: bold;
  font-size: small;
  align-items: left;
  border-color: rgba(104, 9, 9, 0.865);
  border-style: solid;
  border-width: 1.5px;
  background-color: rgba(201, 58, 58, 0.418)
}

.inline-div-value {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  font-weight:normal;
  font-size: small;
  align-items: left;
  border-color: rgba(151, 5, 5, 0.703);
  border-style: solid;
  background-color: rgb(240, 236, 236);
}

.sub-center {
  width: 100px;
  margin: auto;
}

.section-infos-left {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: left;
}

.section-infos-right {
  background-color: rgba(167, 172, 171, 0.24);
  display: flex;
  width: 50%;
  margin-left: 50%;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
}

.candidate-card {
padding: 2%;
gap: 15px;
border-radius: 8px;
position: relative;
width: 80%;
/* opacity: 0px; */
color: hsla(0,0%,20%,1);
font-weight:normal;
background-image: linear-gradient(to bottom right, rgba(234, 222, 222, 0.765), rgba(208, 201, 201, 0.879));
box-shadow: inset rgba(219, 56, 47, 0.726) 0  0px 0px 1px, /* border */
inset hsla(0, 5%, 20%, 0.8) 0 -1px 2px 2px, /* soft SD */
inset hsla(0,0%,0%, .25) 0 -1px 0px 2px, /* bottom SD */
inset hsla(0,0%,100%,.7) 0  2px 1px 2px, /* top HL */
hsla(0,0%, 0%,.15) 0 -5px 6px 4px, /* outer SD */
hsla(0,0%,100%,.5) 0  5px 6px 4px; /* outer HL */ 
transition: color .2s;
margin-bottom: 15px;
}

.container-cta-rev {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  padding: 15px;
  margin-bottom: 0;
}

.center-div {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}