.format-p {
    padding: 2%;
    font-weight: normal;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.spacing {
    margin-bottom: 10%;
}

.test {
    display: flex;
    flex-direction: row;
}