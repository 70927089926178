@import url("boxicons/css/boxicons.min.css");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  width: 100%;
  height: 100vh;
}

.Toastify__close-button--light {
  color: #fff;
  opacity: 1;
}