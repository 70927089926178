.container-card {
    width: 90%;
    margin: 0 auto;
}

/* Main Slot */

#CardDetailsSlot-4 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2.5%;
    width: 100%;
}

#backToResults-3-slot {
    width: 100%;
}

/* Back to results - Button */

.backToResults-2 {
    padding: 0.5% 0.5% 0.5% 0.5%; 
    width: 18%;
    margin-left: 62.5%;
    margin-bottom: 4%;
    border-radius: 10px;
    background-color: #fff7f7; 
    color: #da291c;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    font-size: medium;
    border: 0.5px solid rgb(225, 224, 224);
}

.backToResults-2:hover {
    background-color: #da291c;
    color:#fff;
}

/* Home button */

.home-btn-slot-3 {
    position: relative;
    min-width: 25%;
    max-width: 25%;
    
}

.home-btn-slot-4 {
    position: relative;
    min-width: 25%;
    max-width: 25%;
}

.home-btn-text-2 {
    font-size: medium;
    font-weight: bold;
    font-family: "Houschka Head", sans-serif;
    align-content: center;
    margin-left: 2%;
}

#home-btn-4 {
    color: #da291c;
    cursor: pointer;   
    width: 100%;
    
}

#home-btn-2 {
    color: #da291c;
    cursor: pointer;   
    width: 100%;
    position: relative;
    right: 5%;
    
}


#home-btn-2:hover,
#home-btn-2:focus {
    background-color: #da291c;
    color:#fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    cursor: pointer;
}

#home-btn-2:hover {
    transform: translateY(-1px);
}

#home-btn-2:active {
    background-color: #c85000;
    box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
    transform: translateY(0);
}

#home-btn-4:hover,
#home-btn-4:focus {
    background-color: #da291c;
    color:#fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    cursor: pointer;
}

#home-btn-4::backdrop {
    transform: translateY(-1px);
}

#home-btn-4:active {
    background-color: #c85000;
    box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
    transform: translateY(0);
}