#container-cards {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  gap: 10px;
}

#card {
  text-align: center;
  width: 26.66%;
  height: 301px;
  border: 1px dashed #968c8366;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
}


.input-style {
  padding: 15px 20px 15px 20px;
  font-size: 16px;
  border-radius: 10px;
  outline: none;
  transition: border-color 0.3s;
  background-color: #968c831a;
  border: none;
  width: 300px;
}

.input-style:hover {
  background: #968c8333;
}

.input-style:focus {
  box-shadow: 0 0 0 2px #00a9e0;
}

.container-cta {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

.sidebar-container {
  display: flex;
  gap: 30px;
  height: 100%;
}

.section-resultats {
  width: 100%;
  margin-top: 50px;
}

.toggle-switch-dir {
  position: relative;
  width: 24%;
  display: inline-block;
  text-align: left;
}

.toggle-input-dir {
  display: none;
}

.toggle-label-dir {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
  position: relative;
  height: 30px;
  border:1px solid green;
  background-color: gainsboro;
  transition: background-color 0.3s ease-in-out;
}

.toggle-label-dir:before {
  content: "";
  display: block;
  width: 26px;
  height: 26px;
  background: rgba(0, 128, 0, 0.559);
  border-radius: 20px;
  position: absolute;
  top: 1px;
  left: 0%;
  transition: left 0.3s ease-in-out;
}

.toggle-input-dir:checked + .toggle-label-dir {
  background-color: gainsboro;
  border-color: green;
}

.toggle-input-dir:checked + .toggle-label-dir:before {
  left: 60%;
}

.div-space {
  margin-bottom: 5%;
  margin-top: 5%;
}

.toggle-container-dir {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 100%;
  text-align: center;
}

.toggle-content-left-dir h4,
.toggle-content-left-dir p {
  margin: 0;
  font-size: 75%;
}

.toggle-content-right-dir h4,
.toggle-content-right-dir p {
  margin: 0;
  font-size: 75%;
}

@media screen and (max-width: 768px) {
  #container-cards {
    display: flex;
    flex-direction: column;
  }

  #card {
    width: 90%;
  }

  .input-style.input-simple-search {
    width: 80%;
  }
  
  .container-cta {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1000;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    padding: 15px;
    margin-bottom: 0;
  }

  .sidebar-container {
    gap: 0;
  }
}
