.format-p-email {
    padding: 2%;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
    font-weight: normal;
    font-size: small;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.spacing-email {
    margin-bottom: 10%;
}