.sidebar {
  top: 70px; /* Altezza dell'header + (Padding*2) */
  text-align: center !important;
  left: -30vw;
  height: 100%;
  width: 30vw;
  transition: left 0.1s ease-in-out;
  background-color: rgba(187, 160, 160, 0.518);
}

.back {
  position: relative;
  z-index: 0;
}


.filter-side {
  margin-bottom: 5%;
  margin-top: 5%;
  margin-left: 2.5%;
  width: 95%;
  text-align: center !important;
}

.sidebar-toggle {
  position: absolute;
  top: 100px;
  font-size: medium;
  font-weight: bold;
  font-family: "Houschka Head", sans-serif;
  right: -105px;
  height: 30px;
  border-radius:50px 50px 0 0;
  width: 180px;
  transform: rotate(90deg);
  border-style: none;
  cursor: pointer;
}

.sidebar-toggle:hover {
  background-color: yellow;
}

.content {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  height: 100%;
  transition: left 0.3s ease-in-out;
  background-color: rgb(210, 154, 154);
}

.sidebar.open {
  left: 0;
  height: 720px;
  border-style: outset;
  border-color: rgb(199, 12, 12);
  background-image: linear-gradient(white, white), 
                    linear-gradient(105deg, rgb(238, 59, 59) 0%, rgb(250, 255, 93) 100%);
  background-origin: border-box; /* Ensures gradient applies to the border */
  background-clip: padding-box, border-box; /* Clip background for both layers */
  border: 6px solid transparent;
}


.sidebar.open .sidebar-toggle {
  background-color: yellow;
}


.content.open {
  left: 30vw;
}

.container-cta {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  padding: 15px;
  margin-bottom: 0;
}