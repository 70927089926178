main {
  display: flex;
  justify-content: center;
  }
  
  .container {
    width: 90%;
  }
  
  @media screen and (max-width: 768px) {
    .container {
      width: 90%;
    }
  }
    