.format-p {
    padding: 2%;
    font-weight: normal;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
    font-family: "Houschka Head", sans-serif;
}

.spacing {
    margin-bottom: 10%;
}

.pdf-div {
    width: 100%;
    height: 1000px;
    margin-top: 5%;
    margin-bottom: 20%;
}

.format-frame {
    width: 100%;
    height: 100%;
}