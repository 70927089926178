.sidebar {
    top: 70px; /* Altezza dell'header + (Padding*2) */
    position: absolute;
    text-align: center !important;
    left: -30vw;
    height: 100%;
    width: 30vw;
    transition: left 0.3s ease-in-out;
    background-color: rgba(187, 160, 160, 0.518);
    z-index: 2;
  }
  
  .filter-side {
    margin-bottom: 5%;
    margin-top: 5%;
    margin-left: 2.5%;
    width: 95%;
    text-align: center !important;
  }

  .back {
    position: relative;
    z-index: 0;
  }
  
  .sidebar-toggle {
    position: absolute;
    font-size: medium;
    font-weight: bold;
    font-family: "Houschka Head", sans-serif;
    color: rgb(41, 37, 37);
    background: linear-gradient(90deg, hsla(4, 91%, 50%, 0.692) 0%, #c9a9a6a8 100%);
    right: -105px;
    height: 30px;
    border-radius:50px 50px 0 0;
    border-width: 0.5px;
    width: 180px;
    z-index: 1;
    transform: rotate(90deg);
  }
  .content {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    height: 100%;
    transition: left 0.3s ease-in-out;
    background-color: rgb(210, 154, 154);
  }
  .sidebar.open {
    left: 0;
  }
  
  .content.open {
    left: 30vw;
  }
  
  .container-cta {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1000;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    padding: 15px;
    margin-bottom: 0;
  }