body {
  margin: 0;
  font-family: "Houschka Head", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3 {
  font-family: "Houschka Head", sans-serif;
}

h4 {
  font-family: "Houschka Head", sans-serif;
}

h6 {
  font-family: "Houschka Head", sans-serif;
}

p {
  font-family: "Houschka Head", sans-serif;
}

button {
  font-family: "Houschka Head", sans-serif;
}

h2,
h3 {
  font-weight: 600;
}

.text-red {
  color: #da291c;
}

.text-grey {
  color: #968c83;
}

.text-white {
  color: #fff;
}

.text-center {
  text-align: center;
}

.cursor {
  cursor: pointer;
}

.bold {
  font-weight: 700;
}

.m0 {
  margin: 0;
}

.mb0 {
  margin-bottom: 0;
}

.mt0 {
  margin-top: 0;
}

.ml0 {
  margin-left: 0;
}

.mr0 {
  margin-right: 0;
}

.underline {
  text-decoration: underline;
}

.font12 {
  font-size: 12px;
}

.font14 {
  font-size: 14px;
}

.font16 {
  font-size: 16px;
}

.font18 {
  font-size: 18px;
}

.font28 {
  font-size: 28px;
}

.cta-black {
  padding: 12px 20px 12px 20px;
  gap: 10px;
  border-radius: 10px;
  background: #231f20;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

.cta-black:hover {
  background: #782f40;
  border: none;
  cursor: pointer;
}

.cta-secondary-black {
  padding: 12px 20px 12px 20px;
  gap: 10px;
  border-radius: 10px;
  color: black;
  border: 1px solid black;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

.cta-secondary-black:hover {
  border: 1px solid #782f40;
  color: #782f40;
  cursor: pointer;
}

.cta-secondary-red {
  padding: 12px 20px 12px 20px;
  background-color: #fff;
  gap: 10px;
  border-radius: 10px;
  color: #da291c;
  border: 1px solid #da291c;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

.cta-secondary-red:hover {
  border: 1px solid #782f40;
  color: #782f40;
  cursor: pointer;
}

.cta-red {
  padding: 12px 20px 12px 20px;
  border-radius: 10px;
  background: #da291c;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  min-width: 60%;
  max-width: 60%;
}


.cta-red:hover {
  background-color: #bc2418;
}

.cta-transparent {
  padding: 12px 20px 12px 20px;
  border-radius: 10px;
  background: none;
  color: #da291c;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 600;
}

.orange-tag {
  background: rgba(227, 82, 5, 0.1);
  color: #e35205;
  padding: 1px 8px 1px 8px;
  border-radius: 6px;
  width: max-content;
  font-weight: 700;
}

.green-tag {
  background: rgba(11, 234, 108, 0.1);
  color: #03b636;
  padding: 1px 8px 1px 8px;
  border-radius: 6px;
  width: max-content;
  font-weight: 700;
}

.red-tag {
  background: rgba(236, 33, 6, 0.1);
  color: #e41f11;
  padding: 1px 8px 1px 8px;
  border-radius: 6px;
  width: max-content;
  font-weight: 700;
}

.blue-tag {
  background: rgba(0, 169, 224, 0.1);
  color: #00a9e0;
  padding: 1px 8px 1px 8px;
  border-radius: 6px;
  width: max-content;
  font-weight: 700;
}

.purple-tag {
  background: rgba(173, 26, 172, 0.1);
  color: #ad1aac;
  padding: 1px 8px 1px 8px;
  border-radius: 6px;
  width: max-content;
  font-weight: 700;
}

.tag-remove {
  cursor: pointer;
}

.mobile {
  display: none;
}

@media (max-width: 768px) {
  .mobile {
    display: block;
  }

  .orange-tag {
    margin-top: 0;
  }

  .blue-tag {
    margin-top: 0;
  }

  .purple-tag {
    margin-top: 0;
  }
}

.desktop {
  display: block;
}

@media (max-width: 768px) {
  .desktop {
    display: none;
  }
}

.a-disabled {
  pointer-events: none;
  cursor: default;
}
