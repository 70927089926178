.div-center {
    text-align: center !important;
    margin: auto !important;
}

.row-main {
    display: flex;
    margin-bottom: 10%;
}

.sovra-container-direct {
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-bottom: 10%;
    width: 100%;
}

.column-table-direct {
    display: flex;
    flex-direction: column;
    min-width: 20%;
    max-width: 20%;
    margin-bottom: 5%;
    background-color: #fff7f700;
    padding-bottom: 2%;
    overflow-wrap: break-word;
    border-radius: 10px;
    box-shadow: 0 -2px 10px  #e0dede00;
}


.test-h3 {
    background-color: #da291c;
    color: white;
    width: 100%;
    margin: 0 auto;
}


.inline-div-super-dir {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    margin-bottom: 0.3%;
    text-align: center;
    width: 100%;
    background-color: #ffd9d9;
    margin-bottom: 3%;
    border-color: #da291c;
    border-style: solid;
}

.inline-div-super-dir:hover {
    background-color: rgba(242, 255, 97, 0.622);
}

.inline-div-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    font-weight: bold;
    font-size: small;
    align-items: left;
    border-color: rgba(104, 9, 9, 0.865);
    border-style: solid;
    border-width: 1.5px;
    background-color: rgba(201, 58, 58, 0.418)
}

.inline-div-value {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    font-weight:normal;
    font-size: small;
    align-items: left;
    border-color: rgba(151, 5, 5, 0.703);
    border-style: solid;
    background-color: rgb(240, 236, 236);
}

.no-result {
    margin-right: 40%;
}

.sub-center {
    width: 100px;
    margin: auto;
}

.section-infos-left {
    width: 50%;
    align-items: end;
  }

.section-infos-right-dir {
    align-items: start;
    width: 100%;
}

.candidate-card-dir {
    padding: 2%;
    gap: 15px;
    position: relative;
    width: 90%;
    color: hsla(0, 0%, 20%, 1);
    font-weight: normal;
    background-color: rgb(251, 251, 251);
    margin-bottom: 15px;
    border: 2px solid transparent;
    background-image: linear-gradient(white, white), 
                      linear-gradient(105deg, rgb(238, 59, 59) 0%, rgb(250, 255, 93) 100%);
    background-origin: border-box; 
    background-clip: padding-box, border-box; 
    border-radius: 12px; 
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.sub-container-lr {
    display: flex;
}

.row-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.container-cta-dir {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1000;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
    padding: 15px;
    margin-bottom: 0;
    }

.center-div {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.cta-red-mat {
    padding-left: 2.5%;
    padding-right: 2.5%;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    border-radius: 10px;
    background: #da291c;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 5%;
    font-weight: bold;
    text-decoration: none;
    min-width: 22%;
    max-width: 22%;
    font-size: small;
    }

.cta-red-mat:hover {
    background: #af0e02
}

.cta-red-mat {
    padding-left: 2.5%;
    padding-right: 2.5%;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    border-radius: 10px;
    background: #da291c;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 5%;
    font-weight: bold;
    text-decoration: none;
    min-width: 22%;
    max-width: 22%;
    font-size: small;
    }

.cta-red-mat:hover {
    background: #af0e02
}

.center-span {
    display: flex;
    justify-content: center;
    align-items: center;
}

.check-with-label-idk {
    background-color: #fff7f7;
}

.label-for-check {
    background-color: rgba(128, 128, 128, 0.253);
    border-radius: 12px;
    padding: 0.5%;
    font-weight: bold;
}

.label-for-check-idk {
    color: #da291c;
    border-radius: 12px;
    padding: 0.5%;
    font-weight: bold;
    width:19rem;
}

.check-with-label:checked + .label-for-check {
    background-color: #2fb12b3c;
  }

  .check-with-label-idk:checked + .label-for-check-idk {
    background-color: #b12b460f;
  }


.go-back-btn {
    position: relative;
    top: 14%;
    margin-bottom: 2%;
    padding: 1% 3%;
    border-radius: 10px;
    background: #da291c;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: small;
    font-weight: bold;
    text-decoration: none;
    display: inline-flex; /* Align text and arrow */
    align-items: center; /* Center vertically */
}

.go-back-btn:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 5px solid transparent; 
    border-bottom: 5px solid transparent; 
    border-right: 8px solid #fff; 
    margin-right: 5px; 
}

.go-back-btn:hover {
    background: #af0e02
}


.home-btn-slot-5 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5rem;
    background-color: #fff7f7;
    border-radius: 10px;
    margin-bottom: 3%;
}

.home-btn-text-5 {
    font-size: medium;
    font-weight: bold;
    align-self: center;
    margin-left: 2%;
    font-family: "Houschka Head", sans-serif;
}

.home-btn-text-6 {
    font-size: medium;
    font-weight: bold;
    align-self: flex-end;
    margin-left: 2%;
    font-family: "Houschka Head", sans-serif;
}

#home-btn {
    color: #da291c;
    cursor: pointer;   
    flex-grow: 1;
    display: inline-flex;
    margin-left: 0%;
    position: relative;
}

#home-btn:hover,
#home-btn:focus {
    background-color: #da291c;
    color:#fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    cursor: pointer;
}

#home-btn:hover {
    transform: translateY(-1px);
}

#home-btn:active {
    background-color: #c85000;
    box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
    transform: translateY(0);
}

/* dropdown */

.dropdown-slot {
    display: flex;
    max-height: 3%;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 100%;
    align-items: center; 
    user-select: none;
}


.dropdown-slot-active {
    display: flex;
    margin-top: 2%;
    margin-left: 0%;
    margin-bottom: 0%;
    align-items: center; 
    user-select: none;
}

.dropdown-slot:hover {
    font-weight: bold;
    color: #30b12b;
    cursor: pointer;
}

.dropdown-text {
    position: flex;
    font-size: medium;
    font-family: "Houschka Head", sans-serif;
    font-weight: bold;
}

.dropdown-text-hidden {
    content:'ciao';
}

#dropdown-full {
    max-height: 3%;
    min-height: 1%;
}

/* info text */

.match-reason {
    font-size: medium;
    margin-bottom: 2.5%;
    margin-top: 1%;
    margin-left: 0.5%;
}

.match-reason-hidden {
    visibility:hidden;
    display: none;
}


/* arrow icon */

.arrow-icon-rotated {
    margin-left: 0%;
}