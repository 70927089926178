.button-action-dir {
    background-color: rgba(215, 232, 215, 0.237);
    border-style: solid;
    border-color: green;
    color: green;
    border-radius: 20% / 30%;
    width: 100%;
    height: 60%;
    margin-top: 3.5%;
    margin-bottom: 3.5%;
}

.button-action-dir:hover {
    background-color: green;
    color: whitesmoke;
    cursor: pointer;
}

.spacing-div {
    margin-top: 2%;
    margin-bottom: 2%;
    border-color: yellow;
}

.coloring {
    background-color: blue;
}